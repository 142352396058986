export default {
  error_occurred: 'Ľutujeme, vyskytla sa chyba. Skúste to znova o chvíľu.',
  new_content_available: 'K dispozícii je nová aktualizácia stránky. Kliknutím na tlačidlo „Obnoviť“ stránku znova načítate.',
  refresh_button_title: 'Obnoviť',
  force_reload_message: 'Ľutujeme, vyskytla sa chyba. Náš tím už na tom pracuje. Skúste znova načítať web o chvíľu.',
  sign_up: 'Vytvoriť účet',
  service: 'Servis',
  employee: 'Zamestnanec',
  select_employee: 'Vyberte osobu',
  employees: 'Zamestnanci',
  services: 'Služby',
  duration: 'Trvanie',
  from: 'Od',
  select_date: 'Vyberte dátum',
  no_account_question: 'Ešte nemáte účet?',
  name: 'Krstné meno',
  surname: 'Priezvisko',
  name_and_surname: 'Celé meno',
  phone: 'Telefón',
  password: 'Heslo',
  field_is_required: 'Toto pole je povinné.',
  value_is_too_long: 'Hodnota je príliš dlhá.',
  value_is_too_short: 'Hodnota je príliš krátka.',
  signup_was_successful: 'Registrácia prebehla úspešne. Prihlásenie do panelu.',
  signup_has_failed: 'Registrácia zlyhala. Skúste to znova o chvíľu.',
  email_is_taken: 'Účet s danou e-mailovou adresou už existuje. Ak ste zabudli heslo, použite možnosť pripomenutia.',
  email_or_password_incorrect: 'E-mailová adresa alebo heslo sú nesprávne. Ak ste zabudli heslo, použite možnosť pripomenutia.',
  account_not_found: 'Účet nebol nájdený.',
  page_not_found: 'Ojoj! Stránka ktorú ste hľadali sa nenašla :(',
  go_back_to_main_page: 'Vráťte sa na domovskú stránku a skúste to znova.',
  bookings: 'Rezervácie',
  account: 'Môj účet',
  date: 'Dátum',
  cancel: 'Zrušiť',
  date_from: 'Dátum od',
  date_to: 'Dátum do',
  nothing_found_here: 'Nič sa nenašlo.',
  old_password: 'Staré heslo',
  remove: 'Odstrániť',
  phone_is_invalid: 'Telefónne číslo je nesprávne.',
  book: 'Kniha',
  sign_up_2: 'Vytvoriť účet',
  log_in: 'Prihlásiť sa',
  your_email_address: 'Vaša emailová adresa',
  select: 'Vyberte',
  filters: 'Filtre',
  expand: 'Rozbaliť',
  collapse: 'Kolaps',
  bookings_drawer_title: 'Výberom filtrov zobrazíte iba rezervácie, ktoré hľadáte.',
  clear_filters: 'Obnovte filtre',
  show_more: 'Zobraziť viac',
  dont_you_remember_password: 'zabudol si heslo?',
  password_information: 'Heslo by malo pozostávať aspoň z 8 znakov, obsahovať veľké písmeno, číslo a špeciálny znak.',
  forgotten_password_button: 'Pošlite odkaz na zmenu hesla',
  do_you_already_have_an_account: 'Už máte účet?',
  reset_password_title: 'Nastaviť nové heslo',
  reset_password_subtitle: 'Nižšie zadajte svoje nové heslo.',
  reset_password_button: 'Uložte nové heslo',
  profile: 'Profil',
  logout: 'Odhlásiť Sa',
  cancel_booking: 'Zrušte svoju rezerváciu',
  status_approved: 'Schválené',
  status_payment: 'Očakávaná platba',
  status_done: 'Dokončené',
  status_cancelled: 'Zrušené',
  cancel_booking_dialog_title: 'Chcete zrušiť svoju rezerváciu?',
  cancel_booking_dialog_information: 'Táto akcia sa nedá vrátiť späť!',
  cancel_booking_dialog_button: 'Áno, zrušiť moju rezerváciu',
  my_profile: 'Môj profil',
  my_data: 'Moje detaily',
  upload_new_photo: 'Pridajte novú fotku',
  birthday: 'narodeniny',
  save_changes: 'Uložiť zmeny',
  phone_number: 'Telefónne číslo',
  directional: 'Kód oblasti',
  save_new_password: 'Uložte nové heslo',
  new_password: 'Nové heslo',
  password_change: 'Zmeniť heslo',
  view: 'Vyhliadka',
  services_2: 'Služby',
  accept_terms_and_conditions_1: 'Kliknutím na možnosť nižšie súhlasím s',
  accept_terms_and_conditions_2: 'Podmienky',
  accept_terms_and_conditions_3: 'a potvrdzujem, že som si prečítal',
  accept_terms_and_conditions_4: 'Vyhlásenie o ochrane súkromia.',
  to: 'Komu',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Prihlásiť sa',
  email_is_incorrect: 'E-mailová adresa je nesprávna.',
  logged_out: 'Boli ste odhlásení',
  booking_dialog_date_is_already_taken: 'Vybraný čas nie je k dispozícii. Nevadilo by vám vybrať si iné miesto?',
  data_search: 'Hľadanie údajov. Prosím čakajte...',
  no_employees: 'Žiadni zamestnanci',
  no_services: 'Žiadne služby',
  no_categories: 'Žiadne kategórie',
  no_bookings: 'Rezervácie sa nenašli.',
  no_subscriptions: 'Nenašli sa žiadne odbery.',
  female: 'Žena',
  male: 'Muž',
  other: 'Iné',
  no_numeric_in_password: 'V hesle nie je žiadny číselný znak.',
  no_lowercase_in_password: 'V hesle nie je žiadne malé písmeno.',
  no_uppercase_in_password: 'V hesle nie je žiadne veľké písmeno.',
  no_special_character: 'Žiadna špeciálna postava.',
  min_n_characters: 'Minimálny počet znakov je @{min}.',
  max_n_characters: 'Maximálny počet znakov je @{max}.',
  email: 'E-mail',
  reset_password_fail_text: 'Heslo nebolo zmenené. Platnosť odkazu pravdepodobne vypršala.',
  reset_password_success_title: 'Vaše heslo bolo zmenené.',
  reset_password_success_text: 'Môžete sa prihlásiť pomocou nového hesla.',
  account_activation: 'Aktivácia účtu',
  account_activation_success: 'Účet bol aktivovaný.',
  account_activation_fail: 'Pri aktivácii vášho účtu sa vyskytol problém. Zdá sa, že účet je už aktívny alebo odkaz už nie je platný.',
  account_activation_processing: 'Aktivuje sa váš účet. Prosím čakajte...',
  account_activation_resend_title: 'Účet nebol aktivovaný',
  account_activation_resend_text: 'Aktivujte svoj účet kliknutím na aktivačný odkaz v e-maile, ktorý sme vám poslali.',
  account_activation_resend_button: 'Poslať znova',
  confirm_send_activate_link_dialog_subtitle: 'Na predtým uvedenú e-mailovú adresu sme poslali e-mail s aktivačným odkazom.',
  file_is_too_large: 'Vybratý súbor je príliš veľký.',
  appointment_title: 'Vyberte osobu, dátum a čas rezervácie',
  others: 'Iné',
  countryLabel: 'Krajina',
  requiredMessages: 'Vyžaduje sa telefónne číslo',
  date_of_visit: 'Dátum',
  hour_of_visit: 'Čas',
  thank_you_for_booking: 'Vaša rezervácia',
  no_free_hours: 'Vo vybratý deň nie sú dostupné žiadne časové úseky.',
  any_person: 'Náhodný výber',
  password_changed: 'Vaše heslo bolo zmenené',
  incorrect_old_password: 'Staré heslo je nesprávne',
  error404: 'Chyba 404',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'OK',
  web_page_temporary_disabled: 'Prepáč! Táto stránka bola dočasne zakázaná.',
  profile_update_success: 'Vaše údaje boli uložené.',
  form_is_invalid: 'Formulár obsahuje chyby. Skontrolujte zvýraznené polia a skúste to znova.',
  search: 'Vyhľadávanie',
  pay_online_button_title: 'Platba online',
  account_not_activated_info: 'E-mailová adresa nebola potvrdená. Potvrďte svoju e-mailovú adresu kliknutím na odkaz v odoslanej správe.',
  paid: 'Zaplatené',
  payments: 'Platby',
  payment_service: 'Servis',
  payment_date: 'Dátum',
  payment_price: 'Cena',
  payment_success_title: 'Platba prebehla úspešne, ďakujeme!',
  payment_fail_title: 'Platba zlyhala, skúste to znova.',
  pay_again: 'Zaplatiť znova',
  newsletter_subscribe_success: 'Ďakujem! Vaša e-mailová adresa bola potvrdená.',
  newsletter_subscribe_fail: 'Pri aktivácii vašej e-mailovej adresy sa vyskytol problém. Zdá sa, že e-mailová adresa je už aktívna alebo odkaz už nie je platný.',
  newsletter_send_again: 'Poslať znova',
  newsletter_send_again_success: 'Aktivačný odkaz bol znova odoslaný.',
  send_again_fail: 'Pri generovaní nového aktivačného odkazu sa vyskytol problém, možno je vaša e-mailová adresa už aktívna alebo ste práve vygenerovali odkaz.',
  newsletter_email_is_used: 'E-mailová adresa je už na zozname, ak adresa nebola potvrdená, môžeme vám znova poslať aktivačný odkaz.',
  newsletter_unsubscribe_success: 'Ďakujem! Vaša e-mailová adresa bola odstránená.',
  newsletter_unsubscribe_fail: 'Pri odstraňovaní e-mailovej adresy sa vyskytol problém. Zdá sa, že e-mailová adresa už bola odstránená alebo platnosť odkazu vypršala.',
  booking_payments: 'Rezervácie',
  simple_store_product_transactions: 'Produkty',
  payment_type_select_box: 'Vyberte typ platby',
  payment_description: 'Popis',
  add_to_cart: 'Pridať do košíka',
  remove_from_cart: 'Odstráňte z košíka',
  save: 'Uložiť',
  bookings_cart_summary: 'Zhrnutie rezervácie',
  today: 'Dnes',
  fill_booking_details_title: 'Zadajte podrobnosti',
  create_an_account_question: 'Vytvorte si účet a pošlite heslo na e-mailovú adresu',
  value_is_incorrect: 'Táto hodnota nie je platná.',
  first_available_date: 'Dostupné časové úseky zapnuté',
  service_location_1: 'Adresa',
  service_location_2: 'Telefón',
  service_location_3: 'Google Meet',
  service_location_4: 'Iné',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Poloha',
  booking_not_found: 'Rezervácia sa nenašla.',
  booking_canceled: 'Rezervácia bola zrušená.',
  booking_canceled_fail: 'Túto rezerváciu nie je možné zrušiť.',
  day: 'deň',
  week: 'týždeň',
  month: 'mesiac',
  year: 'rok',
  booking_plural_1: 'Rezervácia',
  booking_plural_2: 'Rezervácie',
  booking_plural_3: 'Rezervácie',
  booking_plural_4: 'Rezervácie',
  booking_plural_5: 'Rezervácie',
  day_plural_1: 'Deň',
  day_plural_2: 'Dni',
  day_plural_3: 'Dni',
  day_plural_4: 'Dni',
  day_plural_5: 'Dni',
  week_plural_1: 'Týždeň',
  week_plural_2: 'Týždňov',
  week_plural_3: 'Týždňov',
  week_plural_4: 'Týždňov',
  week_plural_5: 'Týždňov',
  month_plural_1: 'Mesiac',
  month_plural_2: 'Mesiace',
  month_plural_3: 'Mesiace',
  month_plural_4: 'Mesiace',
  month_plural_5: 'Mesiace',
  year_plural_1: 'Rok',
  year_plural_2: 'Rokov',
  year_plural_3: 'Rokov',
  year_plural_4: 'Rokov',
  year_plural_5: 'Rokov',
  tax: 'IČ DPH',
  tax_prefix: 'Predpona',
  get_company_data: 'Vyhľadávanie',
  get_company_data_failed: 'Údaje sa nepodarilo stiahnuť.',
  company_name: 'Meno spoločnosti',
  street_and_number: 'Ulica a číslo',
  postcode: 'Poštové smerovacie číslo',
  city: 'Mesto',
  country: 'Krajina',
  subscribe: 'Prihlásiť sa na odber',
  recurrent_fee_info: 'Účtované každý',
  one_time_fee_info: 'Jednorazový poplatok.',
  new_account_title: 'Vytvorte si nový účet',
  payment_data_title: 'Platobné údaje',
  billing_data_checkbox_question: 'Fakturačné údaje',
  confirmation_modal_header: 'Akcia vyžaduje potvrdenie',
  confirmation_modal_warning: 'Ste si istý, že chcete pokračovať?',
  no_way_to_undo_action: 'Táto akcia sa nedá vrátiť späť!',
  delete_payment_method: 'Odstráňte kartu',
  add_payment_method: 'Pridať kartu',
  fill_card_details: 'Vyplňte platobné údaje.',
  subscriptions: 'Predplatné',
  subscription: 'Predplatné',
  invoice_number: 'Faktúra',
  number: 'ČíSlo',
  status: 'Postavenie',
  details: 'Podrobnosti',
  created_at: 'Vytvorené',
  subscription_status_initiated: 'Nezaplatené',
  subscription_status_active: 'Aktívne',
  subscription_status_inactive: 'Neaktívne',
  subscription_status_canceled: 'Zrušené',
  ends_at: 'Končí o',
  ends_at_default: 'Platí do konca zúčtovacieho obdobia',
  select_subscription_to_pay: 'Vyberte predplatné, ktoré chcete zaplatiť.',
  pay_with_subscription: 'Plaťte predplatným',
  bookings_paid_by_subscription_success_message: 'Zaplatené',
  bookings_not_paid_by_subscription_error_message: 'Rezerváciu nebolo možné zaplatiť pomocou vybratého predplatného.',
  stripe: 'Stripe',
  provider_tag: 'Metóda',
  card: 'Kard',
  cash: 'Hotovosť',
  transfer: 'Prestup',
  promo_code_label: 'Zľavový kód',
  promo_code_invalid_message: 'Poskytnutý zľavový kód je neplatný.',
  payment_method_setup_error: 'Vyskytol sa problém s vašou kartou. Vaša banka odmietla autorizovať kartu na neskoršie transakcie. Kontaktujte svoju banku.',
  active_subscription_missing: 'Rezervácia tejto služby vyžaduje aktívne predplatné. Žiaľ, nemáte žiadne predplatné, ktoré by ste mohli použiť.',
  area_code: 'Kód oblasti',
  usage: 'Použitie',
  create_account_in_stripe_failed: 'Platobný operátor odmietol vaše predplatné údaje. Skontrolujte si prosím správnosť formulára (daňové identifikačné číslo, telefónne číslo, e-mailová adresa).',
  additional_auth_required: 'Vaša banka vyžaduje dodatočnú autorizáciu',
  general_payment_error: 'Vyskytol sa problém s vaším spôsobom platby. Vaša karta pravdepodobne nepodporuje predplatné. Kontaktujte svoju banku alebo vyberte iný spôsob platby.',
  subscription_set_successfully: 'Predplatné je nastavené správne.',
  booking_created: 'Nová rezervácia',
  booking_updated: 'Zmena rezervácie',
  booking_deleted: 'Vymazanie rezervácie',
  booking_reminder: 'Pripomenutie rezervácie',
  email_notifications: 'E-mailové upozornenia',
  sms_notifications: 'SMS notifikácie',
  save_user_preferences: 'Uložiť predvoľby',
  free_spots: 'Ostali miesta',
  no: 'Nie',
  yes: 'Áno',
  p24_inactive: 'Platobný operátor Przelewy24 nemožno použiť. Kontaktujte svojho platobného operátora.',
  stripe_customer_invalid: 'Neplatné ID zákazníka Stripe. Spôsob platby nie je možné pripojiť k zákazníkovi.',
  stripe_resource_invalid: 'Vybraný produkt nie je dostupný. Dostupnosť produktu si overte u operátora platby.',
  account_blocked_message: 'Váš účet je neaktívny. Kontaktujte prosím správcu.',
  time_zone: 'Časové pásmo',
  no_spots_left: 'Dosiahli ste maximálny počet dostupných miest.',
  employee_auth_required: 'Prihláste sa do svojho účtu alebo použite inú e-mailovú adresu. Účty zamestnancov vyžadujú prihlásenie.',
  subpage_auth_required: 'Ak chcete zobraziť kartu, prihláste sa do svojho účtu.',
  gross: 'Hrubá suma.',
  subscription_login_info: 'Máte už účet? Prihláste sa nižšie a vyplňte svoje údaje.',
  change_date_time: 'Preplánovať',
  promo_code_checkbox: 'Mám zľavový kód',
  booking_promo_code_label: 'Zadajte zľavový kód',
  select_hour: 'Vyberte si čas',
  id: 'Id',
  booking_rescheduled: 'Vaša rezervácia bola zmenená.',
  booking_rescheduled_fail: 'Túto rezerváciu nie je možné zmeniť.',
  max_user_bookings_error: 'Prekročili ste maximálny počet rezervácií. Vyberte si inú službu alebo nás kontaktujte.',
  in_total: 'Celkom',
  company: 'Spoločnosť',
  individual: 'Súkromná osoba',
  bulk_payment: 'Hromadná platba',
  make_bulk_payment: 'Hromadná platba',
  simple_store_product_confirmation_title: 'Vyplňte nákupný formulár',
  select_service_type: 'Vyberte možnosť služby',
  add_to_calendar_button_label: 'Pridať do kalendára',
  login_to_use_service_with_subscription: 'Prihláste sa do svojho účtu a zarezervujte si termín pre túto službu.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Vybraný termín nie je dostupný. Prosím, vyberte iný voľný termín. V prípade rezervácie viacerých termínov sa uistite, že termíny sa vzájomne nevylučujú.',
  select_location_calendar_warning: 'Vyberte miesto pre načítanie dostupných časov.',
  selectable_location_online_title: 'Online',
  change: 'Zmeniť',
  show_less_hours_button: 'Zobraziť menej hodín',
  show_more_hours_button: 'Zobraziť viac hodín',
  add_another_button_title: 'Pridať ďalší',
  close: 'Zatvoriť',
  selected_time_slots: 'Vybrané časové sloty',
  select_location: 'Vyberte miesto',
  captcha_invalid_error_notification: 'Overenie captcha zlyhalo. Skúste stránku znovu načítať, ak problém pretrváva.',
  verify_code_incorrect_error: 'Zadaný kód je nesprávny.',
  verify_code_too_many_requests: 'Momentálne nemôžeme vygenerovať nový kód. Skúste to neskôr alebo použite kód, ktorý sme vám poslali.',
  send_again_button_title: 'Odoslať znova',
  verify_title: 'Overiť',
  verify_code_title: 'Zadajte overovací kód',
  verify_code_description: 'Poslali sme vám 6-miestny overovací kód na váš e-mail. Zadajte ho prosím nižšie:',
  verification_invalid_error_notification: 'Neplatné overenie. Skúste to znova neskôr.',
  too_many_requests: 'Ups! Konáš trochu prirýchlo. Spomaľ a skúste to znova o chvíľu.',
  services_number_title: 'Počet služieb na výber'
}
